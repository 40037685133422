export default function loadExternalScript({ src, id }) {
  const s = document.createElement('script');
  s.id = id;
  s.type = 'text/javascript';
  s.crossOrigin = 'anonymous';
  s.src = src;
  document.body.appendChild(s);

  return new Promise((res, rej) => {
    s.onload = function () {
      res();
    };
    s.onerror = function () {
      rej();
    };
  });
}
